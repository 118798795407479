var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vertical-tabs-component", {
    attrs: { tabs: _vm.tabs, isRouterContent: true }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }